<template>
  <!--
    Used to display the CutConfigs that are used in the videoPostprocessing Themes.
  -->
  <div class="cutConfigList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(cutConfig)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(cutConfig)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editCutConfig(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="configTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="sourceNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="sourceNameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a source name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('sourceNameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="startEventReporterFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="startEventReporterFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an event reporter"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('startEventReporterFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="endEventReporterFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="endEventReporterFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an event reporter"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('endEventReporterFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>

      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <CutConfigListItemAdd
          v-if="addingElement"
          :cut-config-id="currentId"
          :source-names="sourceNames"
          :config-types="configTypes"
          :event-reporters="eventReporters"
          :event-keys="eventKeys"
          :transition-types="transitionTypes"
          @cutConfigListItemEdit_refreshCutConfigList="refreshAndClose()"
        />
        <CutConfigListItemEdit
          v-else
          :cut-config-id="currentId"
          :source-names="sourceNames"
          :config-types="configTypes"
          :event-reporters="eventReporters"
          :event-keys="eventKeys"
          :transition-types="transitionTypes"
          @cutConfigListItemEdit_refreshCutConfigList="refreshAndClose()"
        />
      </Sidebar>
    </template>
    
    <SweetModal
      ref="confirmDelete"
      :title="$t('cutConfigListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('cutConfigListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeCutConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "CutConfigList",
  metaInfo () {
    return {
      title: this.$t('cutConfigListComp.cutConfigList')
    }
  },
  components: {
    Sidebar,
    CutConfigListItemEdit: () => import('@/components/VideoPostprocessing/CutConfig/CutConfigListItemEdit.vue'),
    CutConfigListItemAdd: () => import('@/components/VideoPostprocessing/CutConfig/CutConfigListItemAdd.vue'),
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      cutConfig: null,
      showSidebar: false,
      currentId: null,
      addingElement: false,
      indexToDelete: null,
      sourceNames: null,
      eventReporters: null,
      eventKeys: null,
      transitionTypes: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          },
          {
            field: 'configType',
            operator: 'contains',
            value: ''
          },
          {
            field: 'sourceName',
            operator: 'contains',
            value: ''
          },
          {
            field: 'startEventReporter',
            operator: 'contains',
            value: ''
          },
          {
            field: 'endEventReporter',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          field: 'configType',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: "configTypeFilter",
        },
        {
          field: 'sourceName',
          filterable: true,
          filter: 'text',
          title: 'Source Name',
          filterCell: "sourceNameFilter"
        },
        {
          field: 'startEventReporter',
          filterable: true,
          filter: 'text',
          title: 'Start-Event Reporter',
          width: '220px',
          hideOn: ['lgDown'],
          filterCell: "startEventReporterFilter"
        },
        {
          field: 'endEventReporter',
          filterable: true,
          filter: 'text',
          title: 'End-Event Reporter',
          width: '220px',
          hideOn: ['xlDown'],
          filterCell: "endEventReporterFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '200px'
        }
      ]
    }
  },
  async created () {
    this.configTypes = ['Video', 'Pip'];

    await this.getData();
    await this.getAllCutConfig();

    this.loading = false;
  },
  methods: {

//#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    editCutConfig (cutConfigId) {
      this.currentId = cutConfigId;
      this.addingElement = false;
      this.show();
    },
    addCutConfig () {
      this.currentId = 9999;
      this.addingElement = true;
      this.show();
    },
//#endregion

//#region API-calls
    async getData () {
      await this.axios.all([
        this.axios.get(`/VideoPostprocessing/GetSourceNames`),
        this.axios.get(`/VideoPostprocessing/GetEventKeys`),
        this.axios.get(`/VideoPostprocessing/GetEventReporters`),
        this.axios.get(`/VideoPostprocessing/GetTransitionTypes`)
      ])
        .then(this.axios.spread((nameResponse, keyResponse, reporterResponse, transitionTypesResponse) => {
          this.sourceNames = nameResponse.data;
          this.eventKeys = keyResponse.data;
          this.eventReporters = reporterResponse.data;
          this.transitionTypes = transitionTypesResponse.data;
        }));
    },
    async getAllCutConfig () {
      await this.axios.get('/VideoPostprocessing/GetAllCutConfig')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.cutConfig = response.data;
        });
    },
    refreshAndClose () {
      this.getAllCutConfig();
      this.close();
      this.$emit("reloadAuditLogs");
    },
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    removeCutConfig () {
      this.axios.delete(`/VideoPostprocessing/DeleteCutConfig?id=${ this.indexToDelete }`)
        .then(() => {
          this.getAllCutConfig();
          this.$emit("reloadAuditLogs");
          this.$snotify.success(this.$t('cutConfigListComp.successfullyRemoved'));
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
        });
    }
//#endregion
  }
}
</script>
<style>
.cutConfigList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>